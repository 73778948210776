<!--
 @fileName    : Account.vue
 @author      : llz
 @date        : Created in 2022-04-15 15:04:04
 @description : 账号管理
 @version     :

 鹿乃yyds!!!
-->
<template>
  <!--卡片式的面板-->
  <el-card>
    <main-header main-title="账号管理">
      <template #btn>
        <el-button type="primary" @click="addOrUpdateAccount()" v-if="isSuperAdmin">新增</el-button>
      </template>
    </main-header>
    <div class="account">
      <!--<el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="addOrUpdateAccount()" v-if="isSuperAdmin">新增</el-button>
        </el-form-item>
      </el-form>-->
      <el-table :data="accountTableData" border v-loading="dataListLoading" style="width: 100%">
        <el-table-column prop="type" header-align="center" align="center" label="账号类型" :formatter="typeFormatter"></el-table-column>
        <el-table-column prop="username" header-align="center" align="center" label="姓名"></el-table-column>
        <el-table-column prop="tel" header-align="center" align="center" label="登录手机号"></el-table-column>
        <el-table-column prop="creationTime" header-align="center" align="center" label="添加时间"></el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="180" label="操作" v-if="isSuperAdmin">
          <template v-slot="scope">
            <el-button type="text" size="small" v-if="isSuperAdmin && scope.row.id !== 1" @click="addOrUpdateAccount(scope.row.id, scope.row.username, scope.row.tel, scope.row.type)">编辑
            </el-button>
            <el-button type="text" size="small" v-if="isSuperAdmin && scope.row.id !== 1" @click="deleteAccount(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalSize"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
    <account-add-or-update v-if="addOrUpdateVisible" ref="accountAddOrUpdate" @refreshDataList="refreshAccountDataList()"></account-add-or-update>
  </el-card>

</template>

<script>
import AccountAddOrUpdate from "./Account-add-or-update";
import {accountDeleteById, accountQueryByPage} from "../../api/account";
import {getUserId} from "../../utils/cookie";
import MainHeader from "../../components/layout/mainHeader";

export default {
  name: "Account",
  components: {MainHeader, AccountAddOrUpdate},
  data() {
    return {
      // searchForm:{},
      accountTableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalSize: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
    }
  },
  computed: {
    userId() {
      return Number(getUserId())
    },

    isSuperAdmin() {
      return Number(getUserId()) === 1
    }
  },
  activated() {
    this.getAccountDataList()
    console.log(this.userId)
  },
  methods: {
    getAccountDataList() {
      this.dataListLoading = true
      accountQueryByPage(this.pageIndex, this.pageSize).then((res) => {
        if (res && res.code === 1) {
          this.accountTableData = res.data.content
          this.totalSize = res.data.totalElements
        } else {
          this.accountTableData = []
          this.totalSize = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getAccountDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getAccountDataList()
    },

    //新增或编辑
    addOrUpdateAccount(id, username, tel, type) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.accountAddOrUpdate.init(id, username, tel, type)
      })
    },

    /**
     * 删除账户
     * @param id
     */
    deleteAccount(id) {
      console.log(id);
      this.$confirm('确定要删除该账号?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        accountDeleteById(id).then((res) => {
          if (res && res.code === 1) {
            this.$message({
              message: '删除账号成功!',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.refreshAccountDataList()//刷新父组件的表格内容
              }
            })
          } else {
            this.$message.error(res.data[0])
          }
        })
      })
    },

    /**
     * 回到第一页，刷新表格
     */
    refreshAccountDataList() {
      this.pageIndex = 1
      this.getAccountDataList()
    },


    typeFormatter(row, column, cellValue) {
      if (cellValue === 0) {
        return '运营人员'
      } else if (cellValue === 1) {
        return '管理员'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card {
  height: 100%;

  .account {
    padding: 15px 10px;
  }
}
</style>
