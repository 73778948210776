<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'"
             :close-on-click-modal="false"
             :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="accountForm" @keyup.enter.native="dataFormSubmit()" label-width="100px">
      <el-form-item label="账户类型" prop="accountType">
        <el-radio-group v-model="dataForm.accountType">
          <el-radio :label="0">运营人员</el-radio>
          <el-radio :label="1">管理员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="姓名" prop="accountName">
        <el-input v-model="dataForm.accountName" placeholder="请输入账号名"></el-input>
      </el-form-item>
      <el-form-item label="登录手机号" prop="phoneCode">
        <el-input v-model="dataForm.phoneCode" placeholder="请输入登录手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!dataForm.id">
        <el-input type="password" v-model="dataForm.password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passwordRepeat" v-if="!dataForm.id">
        <el-input type="password" v-model="dataForm.passwordRepeat" placeholder="请再次输入密码以确认" show-password></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {accountInsert, accountUpdate} from "../../api/account";

export default {
  name: "Account-add-or-update",
  data() {
    // let passwordValidater = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('密码不能为空'))
    //   }
    //   callback()
    // }

    let passwordRepeatValidater = (rule, value, callback) => {
      if (value !== this.dataForm.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }

    let telValidater = (rule, value, callback) => {
      let telStr = /^[1][0-9]{10}$/
      if (!telStr.test(value)) {
        callback(new Error('请输入正确格式的手机号'))
      } else {
        callback()
      }
    }

    return {
      visible: false,
      dataForm: {
        id: '', //编辑时需要传id
        accountName: '',
        phoneCode: '',
        accountType: 0, //默认运营人员
        password: '',
        passwordRepeat: '' //二次确认
      },
      dataRule: {
        accountType: [
          {required: true, message: '请选择账号类型', trigger: 'change'}
        ],
        accountName: [
          {required: true, message: 'cron表达式不能为空', trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '手机号不能为空', trigger: 'blur'},
          {validator: telValidater, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        passwordRepeat: [
          {required: true, message: '确认密码不能为空', trigger: 'blur'},
          {validator: passwordRepeatValidater, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id, username, tel, type) {
      console.log(id, username, tel, type)
      this.dataForm.id = id || ''
      this.visible = true
      this.$nextTick(() => {
        this.$refs["accountForm"].resetFields()
        if (this.dataForm.id) { //编辑时的回显
          this.dataForm.accountName = username
          this.dataForm.phoneCode = tel
          this.dataForm.accountType = type
        }
      })
    },

    dataFormSubmit() {
      this.$refs['accountForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.id) { //编辑
            accountUpdate(this.dataForm.id, this.dataForm.phoneCode, this.dataForm.accountType, this.dataForm.accountName).then((res) => {
              if (res && res.code === 1) {
                this.$message.success("编辑成功")
                this.visible = false
                this.$emit('refreshDataList') //刷新表格
              } else {
                this.$message.error(res.data[0])
              }
            })
          } else { //新增
            accountInsert(this.dataForm.phoneCode, this.dataForm.password, this.dataForm.accountType, this.dataForm.accountName).then((res) => {
              if (res && res.code === 1) {
                this.$message.success("新增账号成功")
                this.visible = false
                this.$emit('refreshDataList') //刷新表格
              } else {
                this.$message.error(res.data[0])
              }
            })
          }
        } else {
          this.$message.error("保存失败!")
          return false;
        }
      });


    }
  }
}
</script>

<style scoped>

</style>
